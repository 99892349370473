import { StaffUserType, StaffListType, JobFunctions } from './constants';
import { featureFlags } from './featureFlags';

export const changeJobFunctionMapping = (
  navkey: string,
  val: number,
  mapping: any,
  staffUserType: string,
  setMapping: any,
  setLoading: (loading: boolean) => void,
  setValidationErrorRows: (validationErrorRows: any) => void,
): void => {
  setLoading(true);
  const updatedMapping = { ...mapping };
  if (val || val === 0) {
    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      jobFunction: val,
    };
    if (staffUserType === StaffUserType.school && (val === JobFunctions.SCHOOL_TEACHER || val === JobFunctions.SCHOOL_COUNSELOR)) {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        ...(val === JobFunctions.SCHOOL_TEACHER && featureFlags['feature.dataIngest.staffPermissionMapCode'] ? { isTeacher: true } : {}),
        ...(val === JobFunctions.SCHOOL_COUNSELOR && featureFlags['feature.dataIngest.staffPermissionMapCode'] ? { isCounselor: true } : {}),
      };
    }
  } else {
    delete updatedMapping[navkey]?.jobFunction;
  }
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

export const setDefaultTeacherMapping = (
  val: boolean,
  setSwitchChecked: (switchChecked: boolean) => void,
  mapping: any,
  setMapping: (mapping: { [key: string]: any }) => void,
  setLoading: (loading: boolean) => void,
  titleUserTypeMap: { [key: string]: boolean },
  setTitleUserTypeMap: (titleUserTypeMap: { [key: string]: boolean }) => void,
): void => {
  setLoading(true);
  if (val) {
    const updatedTitleUserTypeMap = { ...titleUserTypeMap };

    if (mapping['Teacher (D)']?.titles?.length && mapping['Teacher (D)']?.cleverUserType) {
      for (const title of mapping['Teacher (D)']?.titles) {
        const key = `${title}-${mapping['Teacher (D)']?.cleverUserType}`;
        if (updatedTitleUserTypeMap[key]) {
          delete updatedTitleUserTypeMap[key];
        }
      }
    }
    setTitleUserTypeMap(updatedTitleUserTypeMap);

    const updatedMapping = {
      ...mapping,
      ...{
        'Teacher (D)': {
          titles: [],
          cleverUserType: 'Teacher',
          jobFunction: 15,
        },
      },
    };
    setMapping(updatedMapping);
  } else {
    // Delete the exsiting mapping for Teacher (D) to let user map the custom values based on their requirement.
    const updatedMapping = { ...mapping };
    delete updatedMapping['Teacher (D)'];
    setMapping(updatedMapping);
  }

  setSwitchChecked(val);
  setLoading(false);
};

export const changeTitlesMapping = (
  navkey: string,
  val: string[],
  mapping: any,
  setMapping: any,
  titleUserTypeMap: any,
  setTitleUserTypeMap: (titleUserTypeMap: any) => void,
  setValidationErrorRows: (validationErrorRows: any) => void,
  setLoading: (loading: boolean) => void,
  staffUserType: string,
): void => {
  setLoading(true);
  const updatedMapping = { ...mapping };
  const updatedTitleUserTypeMap = { ...titleUserTypeMap };
  const newTitleInserted = val.length > (updatedMapping[navkey]?.titles?.length || 0);
  const titleRemoved = val.length < updatedMapping[navkey]?.titles?.length;

  // for title if any entry is removed, remove the title and corresponding cleverUserType from titleUserTypeMap
  if (titleRemoved) {
    const titlesMap = {};
    for (const title of val) {
      titlesMap[title] = true;
    }
    for (const title of updatedMapping[navkey]?.titles) {
      if (!titlesMap[title]) {
        delete updatedTitleUserTypeMap[`${title}-${updatedMapping[navkey]?.cleverUserType}`];
      }
    }
  }

  // Auto populate clever user type(District Admin) if one title is selected for district users
  // If school user auto populate clever user type(Staff) if one title is selected
  if (newTitleInserted && val.length === 1) {
    if (staffUserType === 'district') {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        cleverUserType: 'District Admin',
        jobFunction: 0,
      };
    } else {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        cleverUserType: 'Staff',
      };
    }
  }

  // validation: For every new entry check if title and cleverUserType combination is unique and mark for validation error if not.
  if (
    updatedMapping[navkey]?.cleverUserType &&
    newTitleInserted &&
    titleUserTypeMap[`${val[val.length - 1]}-${updatedMapping[navkey]?.cleverUserType}`]
  ) {
    setValidationErrorRows({
      [navkey]: {
        titles: true,
      },
    });
    setLoading(false);
    return;
  }

  // for title if everything is cleared then remove the entire object with navkey from mapping
  if (val?.length === 0) {
    delete updatedMapping[navkey];
  } else {
    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      titles: val,
    };

    if (newTitleInserted && updatedMapping[navkey]?.cleverUserType) {
      updatedTitleUserTypeMap[`${val[val.length - 1]}-${updatedMapping[navkey]?.cleverUserType}`] = true;
    }
  }
  setTitleUserTypeMap(updatedTitleUserTypeMap);
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

export const handlePastedText = (
  event,
  dataValue,
  roleMapping,
  changeTitlesMapping,
  setRoleMapping,
  titleUserTypeMap,
  setTitleUserTypeMap,
  setValidationErrorRows,
  setLoading,
  staffUserType,
) => {
  event.preventDefault();
  const pastedText = event.clipboardData.getData('Text');

  if (pastedText) {
    const newTags = pastedText
      .split('\n')
      .map((tag) => tag.trim())
      .filter((tag) => tag && tag !== "'" && tag !== '"' ? true : false);

    console.log('newTags', newTags);

    changeTitlesMapping(
      dataValue,
      Array.from(new Set([...(roleMapping[dataValue]?.titles || []), ...newTags])),
      roleMapping,
      setRoleMapping,
      titleUserTypeMap,
      setTitleUserTypeMap,
      setValidationErrorRows,
      setLoading,
      staffUserType,
    );
  }
};

export const changeCleverUserTypeMapping = (
  navkey: string | undefined,
  val: string | undefined,
  mapping: { [key: string]: any },
  staffUserType: string,
  setMapping: (mapping: { [key: string]: any }) => void,
  titleUserTypeMap: { [key: string]: boolean },
  setTitleUserTypeMap: (setNavRolesMap: { [key: string]: boolean }) => void,
  setValidationErrorRows: (setValidationErrorRows: { [key: string]: any }) => void,
  isValidSelection: number,
  setisValidSelection: (isValidSelection: number) => void,
  setLoading: (loading: boolean) => void,
): void => {
  if (!navkey) return; // returning immediately if navkey is undefined.

  setLoading(true);
  const updatedMapping = { ...mapping };
  const updatedTitleUserTypeMap = { ...titleUserTypeMap };
  if (!updatedMapping[navkey]) {
    updatedMapping[navkey] = {};
  }

  if (val) {
    for (const title of updatedMapping[navkey]?.titles || []) {
      if (titleUserTypeMap[`${title}-${val}`]) {
        setValidationErrorRows({
          [navkey]: {
            cleverUserType: true,
          },
        });
        setisValidSelection(isValidSelection + 1);
        setLoading(false);
        return;
      }
    }

    const valueChanged = updatedMapping[navkey]?.cleverUserType !== val;

    // if value is updated clear the previous titleUserTypeMap entries
    if (valueChanged) {
      updateTitleUserTypeMap(
        updatedTitleUserTypeMap,
        updatedMapping[navkey]?.titles,
        updatedMapping[navkey]?.cleverUserType,
      );
    }

    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      cleverUserType: val,
    };

    if (staffUserType === StaffUserType.school && (val === StaffListType.teacher || val === StaffListType.counselor)) {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        ...(val === StaffListType.teacher && featureFlags['feature.dataIngest.staffPermissionMapCode'] ? { isTeacher: true } : {}),
        ...(val === StaffListType.counselor && featureFlags['feature.dataIngest.staffPermissionMapCode'] ? { isCounselor: true } : {}),
      };
    }

    // update the new ones
    updateTitleUserTypeMap(updatedTitleUserTypeMap, updatedMapping[navkey]?.titles, val, true);
  } else {
    updateTitleUserTypeMap(
      updatedTitleUserTypeMap,
      updatedMapping[navkey]?.titles,
      updatedMapping[navkey]?.cleverUserType,
    );
    delete updatedMapping[navkey]?.cleverUserType;
  }

  setisValidSelection(isValidSelection + 1);
  setTitleUserTypeMap(updatedTitleUserTypeMap);
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

// helper function to add/delete from titleUserTypeMap.
export const updateTitleUserTypeMap = (
  titleUserTypeMap: { [key: string]: boolean },
  titles: string[] | undefined,
  userType: string | undefined,
  add = false,
): void => {
  if (titles && userType) {
    for (const title of titles) {
      const key = `${title}-${userType}`;
      if (add) {
        titleUserTypeMap[key] = true;
      } else {
        delete titleUserTypeMap[key];
      }
    }
  }
};

export const getNavianceRolesMap = (data): any => {
  const navRolesMap = {};
  data.forEach((role) => {
    if (navRolesMap[role.name]) {
      navRolesMap[role.name] = [
        ...navRolesMap[role.name],
        {
          institutionId: role.institutionId,
          id: role.id,
        },
      ];
    } else {
      navRolesMap[role.name] = [
        {
          institutionId: role.institutionId,
          id: role.id,
        },
      ];
    }
  });
  return navRolesMap;
};

export const getRolesMap = (schoolRoles: any[], districtRoles: any[]): Record<number, string> => {
  const rolesMap = {};
  schoolRoles.forEach((role) => {
    rolesMap[role.id] = role.name;
  });
  districtRoles.forEach((role) => {
    rolesMap[role.id] = role.name;
  });
  return rolesMap;
}
